<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>已发行作品列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-divider></el-divider>
    <el-row style="text-align:right;">
      <el-col
        ><el-radio-group v-model="radio1">
          <el-radio-button label="2">周</el-radio-button>
          <el-radio-button label="1">月</el-radio-button>
        </el-radio-group></el-col
      >
    </el-row>
    <el-table class="simple-table" :data="tableData" style="width: 100%;" >
      <el-table-column
       align="center" type="index" label="序号" width="50">
       <template slot-scope="scope" >
    <span>{{(page.pageNum - 1) * page.pageSize + scope.$index + 1}}</span>
       </template>
      </el-table-column>
      <el-table-column label="趋势图">
        <template slot-scope="scope">
          <el-popover
              width="700"
              @show="showTrend(scope)"
              @hide="hideTrend(scope)"
              trigger="click">
            <trend-chart
                :track-id="scope.row.tmeData.tmeId"
                :cp-company-id="scope.row.tmeData.tmeCompanyIdCompanyId"
                :cycle="radio1 === '2' ? 'week' : 'mon'"
                v-if="isShowChart[scope.$index]"
            ></trend-chart>
            <el-button slot="reference" type="text">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="歌曲信息" v-slot="{ row }" width="220">
        <div class="track-info">
          <el-image
            class="cover"
            :src="row.cover || '/assets/defaultCover.png'"
          ></el-image>
          <div class="track-info-message">
            <div>
              <span class="text">{{ row.name }}</span>
            </div>
            <div>
              {{ row.tmeData.singerName }}
            </div>
          </div>
        </div>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        filter-change="identity"
        label="发行身份"
        prop="role"
        v-slot="{ row }"
        width="100"
      >
        <div style="text-align: center">{{ row.role.join('/') }}</div>
      </el-table-column> -->
      <el-table-column
        align="center"
        label="发行时间"
        prop="onlineTime"
        v-slot="{ row }"
        width="100"
      >
        {{ $timestampToDate(row.onlineTime) }}
      </el-table-column>
      <el-table-column
        v-if="radio1 == 1"
        align="center"
        prop="tmeData.playCnt"
        width="100"
        label="播放指数/月"
      >
      </el-table-column>
      <el-table-column
        v-else
        align="center"
        prop="tmeData.playCntWeek"
        width="100"
        label="播放指数/周"
      >
      </el-table-column>
      <el-table-column
        v-if="radio1 == 1"
        align="center"
        width="100"
        prop="tmeData.favCnt"
        label="收藏指数/月"
      >
      </el-table-column>
      <el-table-column
        v-else
        align="center"
        width="100"
        prop="tmeData.favCntWeek"
        label="收藏指数/周"
      >
      </el-table-column>
      <el-table-column
        v-if="radio1 == 1"
        width="100"
        align="center"
        prop="tmeData.downloadCnt"
        label="下载指数/月"
      >
      </el-table-column>
      <el-table-column
        v-else
        width="100"
        align="center"
        prop="tmeData.downloadCntWeek"
        label="下载指数/周"
      >
      </el-table-column>
      <el-table-column  align="center" label="操作" v-slot="{ row }">
        <el-link type="primary" @click="operation(row)">详情</el-link>
      </el-table-column>
    </el-table>

    <div style="text-align: center">
      <el-pagination
        layout="prev, pager, next ,total"
        :current-page.sync="page.pageNum"
        :total="total"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from '../api'
import TrendChart from "../components/TrendChart"
export default {
  name: 'PublishList',
  components: {TrendChart},
  data() {
    return {
      tableData: [],
      page: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      //   月周
      radio1: '2',
      isShowChart:[],
      cardInfo:{}
    }
  },
  mounted() {
    this.getTableData()

  },
  methods: {
    getTableData() {
      api.getPublishedList(this.page).then((r) => {
        // console.log(r)
        if (r.data == null) {
          this.tableData = []
        } else {
          this.tableData = r.data.list || []
          this.total = r.data.total
          this.tableData.forEach((item) => {
            this.isShowChart.push(false)
            if (item.tmeData == null) {
              item.tmeData = {}
            }
          })
        }
      })
    },
    // 分页
    currentChange(val) {
      this.page.pageNum = val
        this.getTableData()
    },
    operation(val) {
      this.$router.push({ name: 'PublishedView', params: { data: val } })
    },
    showTrend(value){
      this.isShowChart.splice(value.$index, 1, true)
    },
    hideTrend(value){
      this.isShowChart.splice(value.$index, 1, false)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/simple-table.scss';
  .el-divider{
    background-color: #f5f5f5;
  }
.cover {
  width: 60px;
  height: 60px;
  margin-right: 12px;
}
.track-info {
  display: flex;
}
.track-info-message {
  display: flex;
  width: 128px;
  flex-direction: column;
  justify-content: space-between;
}
</style>
