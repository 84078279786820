<template>
  <div style="padding: 20px">
    <el-row :gutter="10" type="flex" justify="space-between" style="padding-bottom: 25px">
      <el-col>
        <el-radio-group v-model="platform">
          <el-radio-button label="TME"></el-radio-button>
          <el-radio-button label="QQ音乐"></el-radio-button>
          <el-radio-button label="酷狗"></el-radio-button>
          <el-radio-button label="酷我"></el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <div :id="'chart-' + trackId" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: "TrendChart",
  props: ['trackId', 'cpCompanyId', 'cycle'],
  data(){
    return {
      chart: null,
      platform: 'TME',
      platformCode: 0,
      trendData: [],
      option: {}
    }
  },
  mounted() {
    // dateFormat("YYYY-mm-dd HH:MM", date)
    this.chart = echarts.init(document.getElementById('chart-' + this.trackId))
    this.getData()
  },
  watch: {
    platform(a){
      this.platformCode = ['TME', '酷狗', 'QQ音乐', '酷我'].indexOf(a)
      this.getData()
    }
  },
  methods: {
    getData(){
      this.chart.showLoading({text: '', color: '#FF004D'})
      this.$post('/api/tmeplatform/songtrend', {
        tmeid: this.trackId,
        platform: ['tme', 'kg', 'qq', 'kw'][this.platformCode],
        cycle: this.cycle,
        company: this.cpCompanyId
      }, {
        baseURL: process.env.VUE_APP_BASE_API_ADMIN
      }).then(r => {
        this.chart.hideLoading()
        this.trendData = r.data.list
        this.option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['播放指数', '下载指数', '收藏指数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.trendData.map(function (item) {
              return item.impDate
            }),
            axisLabel: {
              formatter: function (value) {
                let v = value.split('')
                v.splice(4, 0, '-')
                v.splice(7, 0, '-')
                const date = new Date(v.join(''));
                return [date.getMonth() + 1, date.getDate()].join('-');
              }
            }
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '播放指数',
              type: 'line',
              showSymbol: false,
              data: this.trendData.map(function (item) {
                return item.playCnt;
              })
            },
            {
              name: '下载指数',
              type: 'line',
              showSymbol: false,
              data: this.trendData.map(function (item) {
                return item.downloadCnt;
              })
            },
            {
              name: '收藏指数',
              type: 'line',
              showSymbol: false,
              data: this.trendData.map(function (item) {
                return item.favCnt;
              })
            }
          ]
        }
        this.chart.setOption(this.option)
      }).catch(e => {
        this.chart.hideLoading()
        console.error(e)
      })
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
      }
      return fmt;
    }
  }
}
</script>

<style scoped>
.chart{
  width: 700px;
  height: 400px;
}
</style>
